export const environment = {
  AppID: 20,
  production: true,
  host: 'https://loctitepulse.henkelgroup.cloud/ems/',
  installation_agent_url: 'https://app.loctite-pulse.com/ia',
  rm_parameter_key: 'cc_rm_param',
  debugMode: false,
  taskLimit: 4000,
  locale: 'en',
  debugAuth: false,
  href: '/em',
  appLogo: './assets/images/LoctitePulseLogo.svg',
  hasBusinessCentral: false,
  credentialLogin: true,
  portalLogin: false,
  msalLogin: false,
  msalCredentials: {
    'cosmoconsult.com': {
      clientID: '6a53838d-68f9-4a9b-b53e-db661bc07f4a',
      tenantID:
        'https://login.microsoftonline.com/94d30528-464f-45a5-a95e-816428f57b90',
    },
    'henkel.com': {
      clientID: 'e0e0191f-86a3-461d-a518-d2df8a945792',
      tenantID:
        'https://login.microsoftonline.com/e8701075-0d9e-4ea1-991d-5a0d110a5d29',
    },
    'digitale-baustelle.com(alt)': {
      clientID: '9f016a61-4e49-487e-9478-054a354f04a9',
      tenantID:
        'https://login.microsoftonline.com/6908a520-92cc-460e-abfb-9072746fd838',
    },
    'digitale-baustelle.com': {
      clientID: 'e5958eb7-aa72-4b81-9909-3dc77ae1d2f6',
      tenantID:
        'https://login.microsoftonline.com/6908a520-92cc-460e-abfb-9072746fd838',
    },
  },
  msalPopup: false,
  msalClientID: 'e0e0191f-86a3-461d-a518-d2df8a945792', // henkel
  msalIssuerService: 'https://login.microsoftonline.com/',
  msalIssuerServiceTenant: 'organizations',
  debugCredentials: null,
  // wp stuff:
  authorization_uri: '',
  redirect_uri: 'https://app.loctite-pulse.com/em/login',
  logout_uri: 'https://www.ccsmartequipment.com/',
  client_id: 'cc_loctite_pulse',
  fakeTelemetry: false,
  //isTestInstance: true,
  environmentInstanceName: 'PROD',
  disableTabletView: false,
  disableSignup: true,
  disableSelectedSteamRateCurrency: true,
  mapbox: {
    satelliteView:
      'https://api.mapbox.com/styles/v1/fbannier/ckseazcz54luo18rhr2sefnlh/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
    mapView:
      'https://api.mapbox.com/styles/v1/fbannier/ckna87y6l35an17o5bfy7ehkq/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
    geoCodingToken:
      'pk.eyJ1IjoiZmJhbm5pZXIiLCJhIjoiY2tlMnM0NnllMGM4NzM3bjdqY2hnanRxMiJ9.l9JR-r7QEflf6fNE6hIxFw',
  },
  nanoPreciseDashboardURL: 'https://nanoprecisedataservices.com/nanop_dashboard/',
};
