import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Notification } from '../../../model/object';
import { DateFormatSettings } from '../../../model/settings';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
})
export class NotificationAlertComponent implements OnInit {
  public url: string = '';
  @Input() notificationList: boolean = true;
  @Input() falseAlert: boolean = false;
  @Input() notification: Notification = null;
  @Input() dateFormat: DateFormatSettings = null;
  documentationAdded = false;

  get Critical(): boolean {
    return this.notification && this.notification.isCritical;
  }

  get Warning(): boolean {
    return this.notification && this.notification.isWarning;
  }

  get InActive(): boolean {
    return this.notification && this.notification.inactive;
  }

  get Working(): boolean {
    return this.notification && !this.notification.inactive && !this.notification.isCritical && !this.notification.isWarning;
  }

  get isRotate() {
    if (this.url !== 'notifications')
      if (this.notification.equipmentType == 'Rotating Equipment') return true;
  }

  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }

  get DateFormat(): string {
    return !!this.dateFormat && this.dateFormat.formatString === 'MM/dd/yyyy'
      ? 'MM/dd/yyyy | h:mm a'
      : 'dd-MM-yyyy | HH:mm';
  }

  get Value(): string {
    if (!this.notification) return '';
    return (
      this.formatNumber(this.notification.value) +
      ' ' +
      this.getUnit(this.notification.measuredValue)
    );
  }

  get ValueIncrease(): string {
    if (!this.notification) return '';
    return (
      this.formatNumber(this.notification.value - this.notification.prevValue) +
      ' ' +
      this.getUnit(this.notification.measuredValue)
    );
  }
  get Message(): string {
    if (!this.notification) return '';
    return this.enrichMessage(this.i18n.parse(this.notification.message));
  }

  get Instructions(): string {
    if (!this.notification) return '';
    return this.enrichMessage(this.i18n.parse(this.notification.instructions));
  }

  public overlayDocumentation: boolean = false;
  public overlayInstructions: boolean = false;

  constructor(
    public i18n: I18nService,
    private router: Router,
    public ui: UiService
  ) {}

  ngOnInit() {
    this.url = this.router.url.split('/')[1];
  }

  enrichMessage(message: string) {
    return !!this.notification
      ? message
          .replace('{ObjectName}', this.notification.objectName)
          .replace('{Objektname}', this.notification.objectName)
      : message;
  }

  formatNumber(num: any, precision = 3): string {
    num = +num;
    const epsilon = 0.00000000001;
    if (Math.abs(num) < epsilon) return '0';
    if (Math.abs(num) < Math.pow(10, -precision)) {
      if (num > 0) {
        return (
          '' +
          Math.ceil(num * Math.pow(10, precision)) / Math.pow(10, precision)
        );
      } else {
        return (
          '' +
          Math.floor(num * Math.pow(10, precision)) / Math.pow(10, precision)
        );
      }
    } else {
      return (
        parseInt((num * Math.pow(10, precision)).toString(), 10) /
        Math.pow(10, precision)
      ).toString();
    }
  }

  getUnit(val: any) {
    val = '' + val;
    const temp = val.replace(/[0-9_.,#]/g, '').split(' ');
    if (temp.length === 0) return '';
    return temp[temp.length - 1];
  }

  onDocumentationAdded() {
    this.documentationAdded = true;
  }

  onRepairAndReplace() {
    this.router.navigate([
      'repair',
      this.notification.objectID,
      this.notification.sensorID,
    ]);
  }

  onAssetName() {
    this.router.navigate([
      'asset',
      this.notification.objectID,
      this.notification.sensorID,
    ]);
  }
}
